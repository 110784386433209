import { Controller } from "@hotwired/stimulus"

import SlimSelect from "slim-select"
import "slim-select/dist/slimselect.css"

export default class extends Controller
  connect: ->
    limit = this.data.get("limit")
    placeholder = this.data.get("placeholder")
    searchText = this.data.get("no-results")
    closeOnSelect = this.single

    shouldShowSearch = this.element.length > 3

    this.select = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect,
        allowDeselect: false,
        limit,
        placeholder,
        searchText,
        showSearch: shouldShowSearch
      }
    })

  disconnect: ->
    if this.select
      this.select.destroy()

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
  @targets = [ "driver", "hideable", "disableable" ]
  @classes = [ "hidden" ]
  @values = {
    showWhen: String
  }

  connect: ->
    this.refreshState()

  refreshState: ->
    selected = this.driverTarget.selectedOptions[0].value

    # This needs refactoring to combine these properly
    # it's silly having two iterations for similar
    # behaviours.
    this.hideableTargets.forEach (target) ->
      if selected == target.dataset.toggleFieldsShowWhenValue
        target.hidden = false
      else
        target.hidden = true

    this.disableableTargets.forEach (target) ->
      if selected == target.dataset.toggleFieldsEnableWhenValue
        target.disabled = false
      else
        target.disabled = true

import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails";

application = Application.start()

# Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
